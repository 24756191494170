body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6 !important;
}
.footer {
  background-color: #f2f2f5;
}
.verfication-msg {
  font-weight: 900;
}
.footer-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
