.tablerisk {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.tablerisk th,
.tablerisk td {
  border: 1px solid rgba(128, 128, 128, 0.333);
  padding: 10px;
}
th,
td {
  padding: 10px;
}
.rtl-txt {
  position: absolute;
  transform: translateX(-100%) rotate(-90deg);
  transform-origin: right;
  letter-spacing: 0.01em;
}
.h-fix {
  height: 120px;
  transform: rotate(-90deg);
  letter-spacing: 0.01em;
  text-align: center;
}
.RiskImpact {
  margin-top: 20px;
}
.link-back {
  cursor: pointer;
  color: rgb(63, 63, 207);
  margin-block: 10px;
  display: flex;
  align-items: center;
}
.link-back:hover {
  text-decoration: underline;
}
.tabs-risktable {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
  overflow: auto;
}
.tabs-risktable span:hover {
  background-color: #e2e6ea;
  color: #343a40;
  border-color: #adb5bd;
}
.tabs-risktable span {
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
  padding: 8px;
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(13 202 240);
  color: #ffffff;
  border: 1px solid rgb(13 202 240);
  white-space: nowrap;
}
.tabs-risktable span.active {
  background-color: #f8f9fa;
  color: #6c757d;
  border: 1px solid #ced4da;
}
.page-title {
  font-weight: 600;
  letter-spacing: 0.01em;
  color: #020408;
  text-align: center;
  font-size: 26px;
  border-radius: 9px;
  background-color: #dee2e6;
}
.bg-grey-10 {
  background-color: #dee2e6;
  color: #020408;
}
.analysis-report-message {
  font-size: 17px;
}
.analysis-risk-items{
  font-size: 16px;
}
.analysis-report-heading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px !important;
}
.Analysis-box {
  background-color: white !important;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  /* box-shadow: 3px 3px 10px rgba(0, 30, 87, 0.751); */
  padding: 20px;
  /* display: flex; */
  /* align-items: start; */
  justify-content: space-around;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
