@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

body {
  font-family: "Arial", sans-serif;
  background-color: #F6F6F6 !important;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

html,
body {
  height: 100%;
  margin: 0;
}
.container-fluid {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: calc(100vh - 10px);
  background-color: #faf8f8;
  padding-top: 5px;
  padding-bottom: 5px;
  overflow: auto;
}

.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  padding: 0 20px;
  background-color: #552dcd;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btn-secondary {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #5f49e1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-secondary:hover {
  background-color: #9f8ae6;
}

.form-container {
  background-color: #e1e9ff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(215, 9, 9, 0.1);
  margin: auto;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

.form-label {
  font-size: 16px;
  color: #333;
}

.form-select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  transition: border-color 0.3s;
}

.form-select:focus {
  border-color: #007bff;
  outline: none;
}

.bg-light-blue {
  background-color: #e1e9ff;
}

.select-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
  padding-right: 25px;
}

.three-dot-vertical {
  width: 25px;
  height: 30px !important;
  cursor: pointer;
}

.dropdown-dots .dropdown-toggle::after {
  display: none !important;
}

/* The entire scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

/* The draggable part of the scrollbar */
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

/* The part of the scrollbar not being dragged */
::-webkit-scrollbar-track {
  background-color: #c4d7df;
  border-radius: 10px;
}
