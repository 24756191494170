.table-page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Arial", sans-serif;
}

.styled-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  margin-top: 40px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(74, 5, 179, 0.1);
  font-size: 16px;
  font-family: "Arial", sans-serif;
}
.styled-table thead tr {
  background-color: #434141;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 15px;
  border: 2px solid #000000;
  text-align: left;
  font-size: 16px;
}

.styled-table th {
  background-color: #c4c4cb;
  font-weight: bold;
  color: #2c2a2a;
  text-transform: uppercase;
}

.styled-table tr:nth-child(even) {
  background-color: #f0f8ff;
}

.styled-table tr:nth-child(odd) {
  background-color: #ffffff;
}

.styled-table tr:hover {
  background-color: #f1f1f1;
}

.add-btn,
.delete-btn,
.edit-btn,
.update-btn,
.close-btn,
.add-table-btn {
  padding: 8px 12px;
  margin: 4px;
  border: none;
  cursor: pointer;
}

.add-btn {
  background-color: #4caf50;
  color: white;
}

.delete-btn {
  background-color: #f44336;
  color: white;
}

.edit-btn {
  background-color: #2196f3;
  color: white;
}

.update-btn {
  background-color: #ff9800;
  color: white;
}

.close-btn,
.add-table-btn {
  background-color: #0ba5ed;
  color: white;
}
.close-btn:hover {
  transform: translateY(-10px);
  transition: transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(38, 46, 91, 0.2);
}

.add-btn:hover {
  transform: translateY(-10px);
  transition: transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(38, 46, 91, 0.2);
}

.update-btn:hover {
  transform: translateY(-10px);
  transition: transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(38, 46, 91, 0.2);
}
.delete-btn:hover {
  transform: translateY(-10px);
  transition: transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(38, 46, 91, 0.2);
}
.edit-btn:hover {
  transform: translateY(-10px);
  transition: transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(38, 46, 91, 0.2);
}

.error {
  color: red;
  margin-bottom: 10px;
}

.error-input {
  border: 1px solid red;
  background-color: #fdd;
}
